import request from '@/router/axios';
import {businessUrl} from "@/config/env"

//工单列表
export const list = (row) => {
    return request({
        url : businessUrl + "issue/selectIssue",
        method: "post",
        data: {
            ...row
        }
    })
};

//工单编辑
export const edit = (row) => {
    return request({
        url : businessUrl + "issue/editIssue",
        method: "put",
        data: {
            ...row
        }
    })
};

//工单删除
export const delIssue = (issueCode) => {
    return request({
        url: businessUrl + "issue/deleteIssue",
        method: "put",
        params: {
            issueCode
        }
    })
};

//工单新增
export const add = (row) => {
    return request({
        url : businessUrl + "issue/addIssue",
        method: "post",
        data : {
            ...row
        }
    })
};

//工单沟通表新增
export const addIssueChat = (row) => {
    return request({
        url : businessUrl + "issueChat/addIssueChat",
        method: "post",
        data : {
            ...row
        }
    })
};

//工单沟通列表
export const issueChatList = (row) => {
    return request({
        url : businessUrl + "issueChat/selectIssueChat",
        method: "post",
        data: {
            ...row
        }
    })
};

//工单编辑 客服专用
export const editKeFu = (row) => {
    return request({
        url : businessUrl + "issue/editIssueKeFu",
        method: "put",
        data: {
            ...row
        }
    })
};

//获取区号
export const QHList = (row) => {
    return request({
        url : businessUrl + "phonePrefix/selectPhonePrefix",
        method: "post",
        data: {
            ...row
        }
    })
};